import request from "@/server"

export function menuQuery() {
  return request({
    url: "/api/backend/system/sidebar",
    method: "get",
  })
}

export function storeQuery() {
  return request({
    url: "/api/backend/store/store_select",
    method: "post",
  })
}
