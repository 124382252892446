<template>
  <el-dropdown trigger="click" ref="domRef">
    <svg-icon :icon="icon" :padding="padding" :size="size"></svg-icon>

    <template #dropdown>
      <ul class="dropdown-menu">
        <li v-for="item in options" :key="item.value" @click="change(item)" class="dropdown"
          :class="{ choose: defValue === item.value }">
          {{ item.label }}
        </li>
      </ul>
    </template>
  </el-dropdown>
</template>

<script setup lang="ts">
type DefProps = {
  icon: string
  size: string
  padding: string
  value: string | number
  options: ElOptions[]
}

const emit = defineEmits(["update:value", "change"])
const props = withDefaults(defineProps<DefProps>(), {
  icon: "",
  size: "",
  padding: "",
  value: "",
  options: () => [],
})

const options = computed(() => {
  return props.options
})

const defValue = computed({
  get () {
    return props.value
  },
  set (value) {
    emit("update:value", value)
  },
})

const domRef = ref()
const change = (val: ElOptions) => {
  emit("change", val)
  domRef.value.handleClose()
}
</script>
<style scoped lang="scss">
.dropdown-menu {
  padding: 5px;
  border-radius: 6px;
}

.dropdown {
  padding: 5px 16px;
  margin: 3px 0px;
  font-size: 14px;
  color: #606266;

  &:hover {
    color: #409eff;
    background-color: #ecf5ff;
    cursor: pointer;
    border-radius: 6px;
  }
}

.choose {
  border-radius: 6px;
  background-color: #ecf5ff;
  color: #f77a2d;
}

//   --el-color-primary: #ea5504;
//   --el-color-primary-light-3: #f77a2d;
//   --el-color-primary-light-5: #ff9d57;
//   --el-color-primary-light-7: #ffb980;
//   --el-color-primary-light-8: #ffd2a8;
//   --el-color-primary-light-9: #fff3e6;
</style>
