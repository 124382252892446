import { createRouter, createWebHashHistory } from "vue-router"
import type { RouteRecordRaw } from "vue-router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import { LayoutStore, UserStore } from "@/stores"
import initSocket from "@/socket"

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "login",
    menuId: 1,
    meta: {
      title: "登录",
      icon: "vue",
    },
    component: () => import("@/layout/login/index.vue"),
  },
  // {
  //   path: '/:catchAll(.*)',
  //   name: '404',
  //   menuId: 4,
  //   component: () => import('@/404.vue'),
  // },
  {
    path: "/resetPwd",
    name: "resetPwd",
    menuId: 999999999999,
    meta: {
      title: "忘记密码",
      icon: "vue",
    },
    component: () => import("@/views/sysConf/resetPwd.vue"),
  },
]

const router = createRouter({
  // history: createWebHistory(import.meta.env.VITE_ROUTER_NAME),
  history: createWebHashHistory(),
  routes: routes,
})

function firstPath(list: any[], path: string) {
  if (list[0].redirect) {
    // 重定向
    return list[0].redirect
  } else if (list[0]?.children?.length > 0) {
    // 有下级
    path += list[0].path
    return firstPath(list[0].children, path)
  } else {
    // 最后一个
    return path + "/" + list[0].path
  }
}
// 全局前置导航守卫
router.beforeEach(async (to: any, from: any, next: any) => {
  NProgress.start()

  // 白名单
  const wihteList = ["/login", "/resetPwd"]
  // 无token且不属于白名单。 返回login页面
  if (UserStore().token !== "") {
    //登录
    initSocket()
    LayoutStore().initPerList()
    if (UserStore().menuList.length === 0 && !UserStore().menuLoad) {
      // 重新请求路由
      try {
        // 动态路由注册
        await UserStore().storeInfo()
        const menuData = await UserStore().menuInfo(true)
        menuData.forEach(item => {
          router.addRoute(item)
        })
      } catch (error) {
        UserStore().setToken("")
        next("login")
      }
      // 最后添加 404
      router.addRoute({
        path: "/:catchAll(.*)",
        name: "404",
        menuId: 4,
        component: () => import("@/404.vue"),
      })
      next({ ...to, replace: true })
    } else if (to.path === "/login" || to.path === "/") {
      next({ path: firstPath(UserStore().menuList, "") })
    } else {
      next()
    }
  } else {
    // 未登录
    if (wihteList.indexOf(to.path) !== -1) {
      next()
      NProgress.done()
    } else {
      next("login")
      NProgress.done()
    }
  }
})

// 全局后置导航
router.afterEach((to: any) => {
  document.title = `${to.meta.title || ""}-${import.meta.env.VITE_TITLE}`
  NProgress.done()
})

export default router
