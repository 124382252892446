<template>
  <span
    class="svg-container"
    :style="{
      '--padding': `${padding}`,
      '--size': `${size}`,
    }"
  >
    <!-- 在线图片 -->
    <img v-if="isOnlineSvg" class="svg-icon" :src="icon" />
    <!-- '--svg-icon-url': `url(${icon})`,  svg-icon-online -->
    <!-- svg -->
    <svg v-else class="svg-icon" aria-hidden="true">
      <use :xlink:href="`#${icon}`" :fill="color" />
    </svg>
  </span>
</template>

<script lang="ts" setup>
const props = defineProps({
  // SVG 图标名称或在线URL
  icon: {
    type: String,
    default: "vue",
    required: true,
  },
  padding: {
    type: String,
    default: "0px",
  },
  size: {
    type: String,
    default: "1rem",
  },
  color: {
    type: String,
    deftault: "",
  },
})

const isOnlineSvg = computed(() => /^(https?:)/.test(props.icon))
</script>

<style scoped lang="scss">
.svg-container {
  display: block;
  width: var(--size);
  height: var(--size);
  padding: var(--padding);
}
.svg-icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
  overflow: hidden;
}

// .svg-icon-online {
//   background-color: currentColor;
//   mask-image: var(--svg-icon-url) no-repeat 50% 50%;
//   -webkit-mask-image: var(--svg-icon-url) no-repeat 50% 50%;
//   mask-size: cover;
//   -webkit-mask-size: cover;
//   display: inline-block;
// }
</style>
