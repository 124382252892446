import { ElLoading } from "element-plus"
/* 全局请求 loading */
let loadingInstance: ReturnType<typeof ElLoading.service>

export const loadingSvg = `
<path d="M469.333333 85.333333m42.666667 0l0 0q42.666667 0 42.666667 42.666667l0 128q0 42.666667-42.666667 42.666667l0 0q-42.666667 0-42.666667-42.666667l0-128q0-42.666667 42.666667-42.666667Z" fill="#409EFF" opacity=".8" p-id="12595"></path>
<path d="M469.333333 725.333333m42.666667 0l0 0q42.666667 0 42.666667 42.666667l0 128q0 42.666667-42.666667 42.666667l0 0q-42.666667 0-42.666667-42.666667l0-128q0-42.666667 42.666667-42.666667Z" fill="#409EFF" opacity=".4" p-id="12596"></path>
<path d="M938.666667 469.333333m0 42.666667l0 0q0 42.666667-42.666667 42.666667l-128 0q-42.666667 0-42.666667-42.666667l0 0q0-42.666667 42.666667-42.666667l128 0q42.666667 0 42.666667 42.666667Z" fill="#409EFF" opacity=".2" p-id="12597"></path>
<path d="M298.666667 469.333333m0 42.666667l0 0q0 42.666667-42.666667 42.666667l-128 0q-42.666667 0-42.666667-42.666667l0 0q0-42.666667 42.666667-42.666667l128 0q42.666667 0 42.666667 42.666667Z" fill="#409EFF" opacity=".6" p-id="12598"></path>
<path d="M783.530667 180.138667m30.169889 30.169889l0 0q30.169889 30.169889 0 60.339779l-90.509668 90.509668q-30.169889 30.169889-60.339779 0l0 0q-30.169889-30.169889 0-60.339779l90.509668-90.509668q30.169889-30.169889 60.339779 0Z" fill="#409EFF" opacity=".1" p-id="12599"></path>
<path d="M330.965333 632.661333m30.16989 30.16989l0 0q30.169889 30.169889 0 60.339778l-90.509668 90.509668q-30.169889 30.169889-60.339779 0l0 0q-30.169889-30.169889 0-60.339778l90.509668-90.509668q30.169889-30.169889 60.339779 0Z" fill="#409EFF" opacity=".5" p-id="12600"></path>
<path d="M843.861333 783.530667m-30.169889 30.169889l0 0q-30.169889 30.169889-60.339779 0l-90.509668-90.509668q-30.169889-30.169889 0-60.339779l0 0q30.169889-30.169889 60.339779 0l90.509668 90.509668q30.169889 30.169889 0 60.339779Z" fill="#409EFF" opacity=".3" p-id="12601"></path>
<path d="M391.338667 330.965333m-30.16989 30.16989l0 0q-30.169889 30.169889-60.339778 0l-90.509668-90.509668q-30.169889-30.169889 0-60.339779l0 0q30.169889-30.169889 60.339778 0l90.509668 90.509668q30.169889 30.169889 0 60.339779Z" fill="#409EFF" opacity=".7" p-id="12602"></path>
`
export const loadingSvgViewBox = "0 0 1024 1024"
/**
 * @description 开启 Loading
 * */
const startLoading = () => {
  loadingInstance = ElLoading.service({
    fullscreen: true,
    lock: true,
    text: "Loading",
    svg: loadingSvg,
    svgViewBox: loadingSvgViewBox,
    background: "rgba(255, 255, 255, 0.7)",
  })
}

/**
 * @description 结束 Loading
 * */
const endLoading = () => {
  loadingInstance.close()
}

/**
 * @description 显示全屏加载
 * */
const isShow = ref<boolean>(false)
export const showLoading = () => {
  if (isShow.value === false) {
    startLoading()
  }
  isShow.value = true
}

/**
 * @description 隐藏全屏加载
 * */
export const hideLoading = () => {
  // if (isShow) return

  if (isShow.value === true) {
    endLoading()
  }
  isShow.value = false
}
