import axios from "axios"
import type { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosRequestConfig } from "axios"
import { UserStore, LayoutStore } from "@/stores"
import { showLoading, hideLoading } from "@/hooks/loading"
import { toast } from "../utils/tip"

const whiteApi = ["/api/backend/system/get_file", "/api/backend/system/set_file"]

export interface IAxiosInstance extends AxiosInstance {
  <D = any>(config: AxiosRequestConfig): Promise<D>
}

const request: IAxiosInstance = axios.create({
  // baseURL: import.meta.env.VITE_BASE_API,
  withCredentials: true,
  timeout: 20 * 1000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
})

const pendingRequest = new Map()
// 拼接接口信息
function getRequestKey(config: InternalAxiosRequestConfig<any>) {
  const { url, method, responseType, data } = config
  return [url, method, responseType, JSON.stringify(data)].join("&")
}
// 添加
function addPendingRequest(config: InternalAxiosRequestConfig) {
  const requestKey = getRequestKey(config)
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel)
      }
    })
}
// 移除
function removePendingRequest(config: InternalAxiosRequestConfig) {
  const requestKey = getRequestKey(config)
  if (pendingRequest.has(requestKey)) {
    // 如果是重复的请求，则执行对应的cancel函数
    const cancel = pendingRequest.get(requestKey)
    cancel(requestKey)
    // 将前一次重复的请求移除
    // console.log("取消重复请求：" + requestKey)
    pendingRequest.delete(requestKey)
  }
}

// 请求拦截
request.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 添加请求信息 token

    config.headers.Authorization = UserStore().getToken
    config.headers["store-id"] = UserStore().storeId
    // 添加语言
    config.headers["accept-language"] = LayoutStore().getLanguage
    // 判断FormData 类型
    if (Object.prototype.toString.call(config.data) === "[object FormData]") {
      config.headers["Content-Type"] = "multipart/form-data"
    }

    // 默认空对象
    if (!config.data) config.data = {}

    // if (typeof config.data !== "string") {
    //   // 清除 undefined, null, ''
    //   Object.keys(config.data).forEach(key => {
    //     if ([undefined, null, ""].includes(config.data[key])) {
    //       delete config.data[key]
    //     } else {
    //       const v = config.data[key]
    //       if (typeof v === "string") {
    //         config.data[key] = v.trim()
    //       }
    //     }
    //   })
    // }
    // get请求处理
    if (config.method?.toLocaleLowerCase() === "get") config.params = config.data

    // // 取消重复接口
    // removePendingRequest(config)
    // // 添加非重复接口信息
    // if (!whiteApi.includes(config.url as string)) {
    //   addPendingRequest(config)
    // }

    return config
  },
  error => {
    console.log("request err" + error) // for debug
    return Promise.reject(error)
  },
)
// request;

// 响应拦截
/**
 * 200   data
 * 401   forbidden
 * 403   no root
 * 404   404
 * 500   server error
 * 501   错误验证
 */
request.interceptors.response.use(
  (response: AxiosResponse) => {
    const { data } = response
    if (data.code === 200) {
      // 成功
      return Promise.resolve(data.data)
    } else if ([401, 403].includes(data.code)) {
      toast.error(data.message)
      UserStore().setToken("")
      // 路由表重置
      location.href = "#/login"
      // load.close(0)
      return Promise.reject(data.message)
    } else if ([302].includes(data.code)) {
      //重定向
      //暂时先不处理
      hideLoading()
      return Promise.reject(data)
    } else if ([501].includes(data.code)) {
      //501为请求数据的错误
      //需要用户重新填写数据
      //错误的字段在data.data中
      hideLoading()
      toast.error(data.message)
      return Promise.reject(data)
    } else if ([500].includes(data.code)) {
      //501为请求数据的错误
      //需要用户重新填写数据
      //错误的字段在data.data中
      hideLoading()
      toast.error(data.message)
      return Promise.reject(false)
    } else {
      hideLoading()
      toast.error(data.message)
      return Promise.reject(data)
    }
  },
  (error: any) => {
    console.log("error", error)
    hideLoading()
    if (error.code !== "ERR_CANCELED") toast.error(error.message)
    return Promise.reject(error)
  },
)
export default request
