import { defineStore } from "pinia"
import { messagesType } from "@locales/index"
import { planLog } from "@/api/common"
interface LayoutStore {
  elSize: "" | "large" | "default" | "small"
  silderStatus: boolean
  language: messagesType
  perList: any[]
}

const Layout = defineStore({
  id: "layout",
  state: (): LayoutStore => ({
    elSize: "default",
    silderStatus: false,
    language: localStorage.getItem("language") || import.meta.env.VITE_LANGUAGE,
    perList: [],
  }),
  getters: {
    getLanguage(): messagesType {
      return this.language
    },
  },
  actions: {
    // 更改尺寸
    setSize(e: "" | "large" | "default" | "small") {
      this.elSize = e
    },
    // aside 伸缩
    changeAsideStatus() {
      this.silderStatus = !this.silderStatus
    },
    setLanguage(e: messagesType) {
      this.language = e
      localStorage.setItem("language", e)
      location.reload()
    },
    initPerList() {
      planLog().then(res => {
        this.perList = res.data.map((item: { url: any; id: any; name: any; status: any }) => {
          return {
            id: item.id,
            name: item.name,
            status: item.status,
            per: item.status === 0 ? 0 : 100,
            url: item.url,
            date: new Date().getTime(),
          }
        })
      })
    },
  },
  persist: {
    // 开启持久化
    enabled: true,
    // 选择存储方式和内容
    strategies: [
      {
        storage: sessionStorage,
        paths: ["elSize", "silderStatus"],
      },
    ],
  },
})

export default Layout
