<template>
  <div>
    <el-dropdown trigger="click">
      <slot name="icon"></slot>
      <template #dropdown>
        <el-dropdown-menu class="per-box" v-loading="tableInfo.loading">
          <el-dropdown-item @click="download" v-for="(item, index) in tableInfo.list" :key="item.id">
            <div class="mian">
              <div class="omit" style="padding: 5px 0px">
                {{ `${index + 1}. ${item.name}` }}

                <svg-icon icon="layout-download" padding="5px" size="20px" class="icon-down" v-if="item.url"
                  @click="download(item.url, item.name)"></svg-icon>
              </div>
              <el-progress :percentage="item.per" :status="statusObj[item.status]" striped-flow :duration="10"
                :striped="item.status === 0" class="per" :show-text="false" />
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup lang="ts">
import { LayoutStore, UserStore } from "@/stores"
import { i18n } from "@/locales"

const { t } = i18n.global
const userStore = UserStore()

type PerStatus = 0 | 1 | 3
type PetInfo = {
  id: string
  name: string
  status: PerStatus
  per: number
  date?: number
  url?: string
}

const planChannel = window.echo.join(`plan.${userStore.storeId}`)
planChannel.listen("plan_create", (data: any) => {
  const index = tableInfo.list.findIndex(item => item.id === data.log.id)
  if (index === -1) {
    tableInfo.list.push({
      id: data.log.id,
      name: data.log.name,
      status: 3,
      per: 0,
      url: data.log.url,
      date: new Date().getTime(),
    })
  } else {
    tableInfo.list[index].status = data.log.status
  }
})
planChannel.listen(".plan_success", (data: any) => {
  const index = tableInfo.list.findIndex(item => item.id === data.log.id)
  console.log(index)
  if (index === -1) {
    tableInfo.list.push({
      id: data.log.id,
      name: data.log.name,
      status: 1,
      per: 0,

      date: new Date().getTime(),
    })
  } else {
    tableInfo.list[index].status = data.log.status
  }
})

const statusObj: ("" | "success" | "warning" | "exception")[] = ["", "success", "", "exception"]
// status 0开始  1结束  3异常
const tableInfo = reactive({
  loading: false,
  list: <PetInfo[]>[],
})

// 下载
const download = (url: string, name: string) => {
  let image = new Image();
  image.setAttribute("crossOrigin", "anonymous");
  image.src = url;
  image.onload = () => {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext("2d");
    ctx!.drawImage(image, 0, 0, image.width, image.height);
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob!);
      let a = document.createElement("a");
      a.download = name;
      a.href = url;
      a.click();
      a.remove();
      // 用完释放URL对象
      URL.revokeObjectURL(url);
    });

  };
}

let timer: NodeJS.Timeout | undefined
const autoPer = () => {
  clearInterval(timer)

  tableInfo.list = LayoutStore().perList;
  if (!tableInfo.list.length) return
  timer = setInterval(() => {
    tableInfo.list.forEach(item => {
      if (item.status === 0) {
        const round = Math.random() * Math.random() * 10 + 0.1
        // const num = (1 - Math.exp((-1 * (new Date().getTime() - item.date!)) / (60 * 1000))) * 100
        // const roundNum = num * round

        // if (roundNum > item.per) {
        //   item.per = num >= 95 ? 95 : parseFloat(roundNum.toFixed(2))
        // }

        item.per = item.per + round > 95 ? 95 : parseFloat((item.per + round).toFixed(2))
      } else {
        item.per = 100
      }
    })
  }, 1 * 1000)

}
autoPer()
</script>
<style scoped lang="scss">
.per-table {
  height: 500px;
  overflow-y: auto;
}

.per-box {
  width: 350px;
  height: 500px;

  .mian {
    position: relative;
    width: 100%;
    padding: 10px;

    .icon-down {
      position: relative;
      float: right;
      cursor: pointer;
    }

    :deep(.el-progress__text) {
      min-width: 20px !important;
    }
  }

  .span-text {
    width: 80px;
  }

  .btnBox {
    margin-left: auto;
    float: right;
    width: 150px;
    text-align: right;
  }
}

:deep(.el-dropdown-menu__item:hover) {
  cursor: default;
}
</style>
