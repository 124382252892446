import { requestApi } from "@/api/common"
import { Method } from "axios"

import { defineStore } from "pinia"
type DictInfo = {
  loading: boolean
  error: boolean
  list: ElOptions[]
}
interface cacheStore {
  formId: string //数据id 新增为空
  formKey: string // 表单名称
  formApi: string // api
  formDict: any // 表单字典

  tableRowKey: string // 表格主键 默认id
  tableUpApi?: string // 数据更新api
  tableQueryApi: string //查询api
  tableDict: any // 表格字典
  dictInfo: Map<string, DictInfo>
  dictKeyList: string[]
}

const cacheStore = defineStore({
  id: "cache",
  state: (): cacheStore => ({
    formId: "",
    formKey: "form",
    formApi: "",
    formDict: {},

    tableRowKey: "id",
    tableUpApi: "",
    tableQueryApi: "",
    tableDict: {},

    dictInfo: new Map(),
    dictKeyList: [],
  }),
  getters: {},
  actions: {
    setFormInfo(configInfo: { id?: string; api?: string; key?: string }) {
      const { id, api, key } = configInfo
      this.formId = id ?? ""
      this.formApi = api ?? ""
      this.formKey = key ?? "form"
    },
    setFormDict(value: any): any {
      this.formDict = value
    },

    setTableRowKey(value: string) {
      this.tableRowKey = value ?? "id"
    },
    setTableUpApi(value: string) {
      this.tableUpApi = value ?? ""
    },
    setTableQueryApi(value: string) {
      this.tableQueryApi = value ?? ""
    },
    setTableDict(value: any) {
      this.tableDict = value
    },

    async setDictHttp(optionName: string = "", api: string, method: Method) {
      const addDictMap = async (optionName: string = "", api: string, method: Method) => {
        this.dictInfo.set(optionName, {
          loading: true,
          error: false,
          list: [],
        })
        try {
          const options = await requestApi<ElOptions[]>(api, method, {})
          this.dictInfo.set(optionName, {
            loading: false,
            error: false,
            list: options,
          })
        } catch (error) {
          this.dictInfo.set(optionName, {
            loading: false,
            error: true,
            list: [],
          })
        }
      }
      if (this.dictInfo.has(optionName)) {
        console.log("2", this.dictInfo)
        if (this.dictInfo.get(optionName)?.error === true) await addDictMap(optionName, api, method)
        return
      } else {
        await addDictMap(optionName, api, method)
        // loading 结束后会有 视图未更新 暂时通过延时移除来做
      }
    },

    setDict(obj: { [x: string]: ElOptions[] }) {
      for (const key in obj) {
        this.dictInfo.set(key, {
          loading: false,
          error: false,
          list: obj[key] as ElOptions[],
        })
      }
    },
  },
  persist: {
    // 开启持久化
    enabled: true,
    // 选择存储方式和内容
    strategies: [
      {
        storage: sessionStorage,
        paths: ["formId", "formKey", "formApi"],
      },
    ],
  },
})

export default cacheStore
